import Hero from '../components/Hero';
import Posts from '../components/Posts';

const Home = () => {
  return (
    <>
      <Hero />
      <Posts />
    </>
  );
};

export default Home;
